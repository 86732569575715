.ant-tag {
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  line-height: 21px;
  border: 0;
  border-radius: 16px;

  &:hover {
    opacity: 1;
  }

  &.Ad.Creatives {
    color: #000000;
    background: #D4FC79;
  }

  &.AI.Advertising {
    color: #000000;
    background: #3BFE42;
  }

  &.Brand.Building {
    color: #ffffff;
    background: #E100FF;
  }

  &.Content.Marketing {
    color: #ffffff;
    background: #4127FF;
  }

  &.Conversion.Optimization {
    color: #000000;
    background: #FCFD65;
  }

  &.Email.SMS.Marketing {
    color: #ffffff;
    background: #02AAB0;
  }

  &.Media.Buying {
    color: #ffffff;
    background: #FF635A;
  }

  &.Future.Advertising {
    color: #ffffff;
    background: #D31027;
  }

  &.Other,
  &.Break,
  &.Mixer {
    color: #000000;
    background: #DDDDDD;
  }
}
