.ant-table-tbody {
  .ant-table-row {
    &--drop-over-upward > td {
      border-top: 2px dashed @blue-200;
    }

    &--drop-over-downward > td {
      border-bottom: 2px dashed @blue-200;
    }

    &--draggable {
      cursor: move;
    }
  }

  .ant-table-cell a {
    font-weight: 600;
    text-decoration: underline;
  }
}
