@sider-background-color: @primary;
@sider-menu-item-color: rgb(255, 255, 255);
@sider-menu-item-color-hover: @tertiary;
@sider-menu-item-background: #ffc7001a;
@sider-menu-item-group-color: rgb(128, 128, 128);

.admin-layout {
  background-color: @sider-background-color;

  .ant-menu {
    height: 100%;
    background-color: @sider-background-color;
    border-right: none;
  }

  .logo img {
    width: 170px;
  }

  .ant-layout-content {
    padding: 20px;
    margin-top: 0px;
  }

  .ant-menu-sub.ant-menu-inline {
    .ant-menu-item {
      a {
        padding-left: 24px;
      }
    }
  }

  a span {
    letter-spacing: 2.2px;
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
  }

  .ant-menu-item {
    font-weight: 900;

    a {
      color: @sider-menu-item-color !important;

      &:hover {
        color: @sider-menu-item-color-hover !important;

        svg {
          color: @sider-menu-item-color-hover !important;
        }
      }
    }

    &::after {
      border-right-color: @sider-menu-item-color-hover !important;
    }
  }

  .ant-menu-submenu-title {
    padding-left: 20px !important;
    font-weight: 900;
    color: @sider-menu-item-color;

    .ant-menu-submenu-arrow {
      color: @sider-menu-item-color;
    }

    svg {
      margin-right: 5px;
    }
  }

  .item-selected {
    background-color: @sider-menu-item-background !important;

    a {
      color: @sider-menu-item-color-hover !important;
    }
  }

  .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
    background-color: @sider-menu-item-background !important;

    a {
      color: @sider-menu-item-color-hover !important;

      svg {
        color: @sider-menu-item-color-hover !important;
      }
    }
  }

  .ant-layout-sider-zero-width-trigger {
    background-color: @sider-background-color;
  }
}
