.main-content-section {
  max-width: 100%;
  padding: 20px 0;
  margin: 0 auto;

  .social-post {
    margin-top: 30px;

    &:first-child {
      margin-top: 20px;
    }

    .time {
      font-weight: 500;
    }
  }

  .loading-mask {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  @media only screen and (max-width: 1400px) {
    padding: 20px 0;
  }
}
