.talk-modal {
  width: 90% !important;
  max-width: 920px;

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-body {
    padding: 45px 30px;
  }

  .ant-modal-close {
    top: 15px;
    right: 20px;
  }

  .ant-modal-footer {
    padding: 33px 37px;
  }

  .ics-btn {
    padding: 0;
  }

}

.talk-wrapper {
  &-agenda {
    margin-bottom: 10px;
  }

  &-title {
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    color: #230b34;
  }

  &-details {
    margin-bottom: 15px;
  }

  &-description {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    line-height: 135.1%;
    color: #a3a7b3;
    white-space: pre-wrap;
  }

  &-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    margin-top: 35px;
    margin-bottom: -10px;
    border-top: none;

    .talk-speakers-wrapper {
      display: flex;

      .talk-wrapper-speaker {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-right: 35px;

        &:last-of-type {
          margin-right: 0;
        }

        &-img {
          img {
            border-radius: 50%;
          }
        }

        &-info {
          font-size: 16px;

          &:last-child {
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            line-height: 135.1%;
            color: #c4c4c4;
          }

          &-name {
            font-size: 18px;
            font-weight: 700;
            line-height: 30px;
            color: rgb(35, 11, 52);
          }

          &-job-position {
            font-size: 15px;
            line-height: 20px;
            color: #c4c4c4;
          }

          &-job-company {
            font-size: 15px;
          }
        }
      }
    }
  }

  .countdown-timer {
    height: fit-content;
    margin-left: 5px;
    font-size: 18px;
    font-weight: 800;
    color: rgb(2, 170, 176);
  }

  .calendar-link {
    float: right;
    font-size: 14px;
  }
}
