.extra-information {
  flex: 1;
  min-width: 330px;
  padding: 10px 20px;
  margin-top: 25px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

  &-header {
    position: sticky;
    top: 57px;
    z-index: 3;
    padding: 10px 10px 10px 0;
    padding-top: 5px;
    background: #ffffff;
  }

  .extra-information-companies {
    .extra-information-header {
      margin-top: 40px;
    }
  }

  &-title {
    margin-bottom: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: #4b4c53;
    text-transform: capitalize !important;
    letter-spacing: 0em;
  }
}
