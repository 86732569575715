.connectplexus-video-player {
  position: relative;
  height: 100%;
  background: #181922;

  iframe,
  .theoplayer-skin {
    width: 100%;
    height: 100%;
  }

  .dc-video-player-wrapper {
    height: 100%;
    max-height: inherit;
  }
}

.connectplexus-video-player__overlay {
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: #181922;
}

#s3-video-player,
#jw-video-player,
#vimeo-video-player,
#dacast-video-player {
  background: #181922;
  height: 500px !important;
  position: relative;
  max-height: 500px !important;
}
