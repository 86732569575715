.track-page {
  position: relative;

  .additional-info-container {
    position: relative;

    h2 {
      font-size: 26px;
      font-weight: 700;
      color: #000000;

      @media screen and (max-width: 600px) {
        margin-right: 25px;
        font-size: 23px;
        line-height: 25px;
      }
    }
  }

  .actions {
    z-index: 1;

    @media screen and (max-width: 600px) {
      top: 66px;
      right: 20px;
    }
  }

  > section {
    padding-top: 25px;

    &.full-width {
      width: 100% !important;
      max-width: 1400px;
      margin: auto;

      > header {
        justify-content: center !important;
      }
    }

    @media screen and (max-width: 900px) {
      width: 100%;
      padding: 0px;
    }

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        margin-bottom: 10px;
      }

      @media screen and (max-width: 900px) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
