.recommended-companies-section {
  padding-top: 0;
  border-bottom: 1px solid #d5dee1;

  .speaker-information-container-image {
    align-self: center;
    object-fit: cover;
  }

  .company-list {
    margin: 0 0 12px;
  }

  .view-more-button {
    margin-left: 14px;
  }

  .company-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #f8f8f8;
    }

    &-container {
      display: flex;
      width: 100%;

      img {
        margin-right: 15px;
        border-radius: 50%;
      }

      .company-item-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 100px);

        h6 {
          margin-bottom: 0;
          font-size: 17px;
          font-weight: 600;
          line-height: 19px;
          color: @dark-200;
          letter-spacing: -0.45px;
        }

        .subtitle {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          margin-top: 3px;
        }
      }
    }
  }
}
