.notification-indicator {
  position: relative;
  display: flex;
  align-items: center;
  height: 57px;

  .ant-badge {
    position: absolute;
    top: 22px;
    right: 22px;
  }
}

.notification-drop-down {
  min-width: 300px;
  max-width: 300px;
  max-height: calc(100vh - 100px);
  padding: 10px;
  margin-top: -3px;
  margin-right: -20px;
  overflow-y: auto;
  color: #4b4c53;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #f8f8f8;
  }

  img {
    min-width: 50px;
  }

  .text {
    width: 100%;
    font-size: 16px;
    color: #4b4c53;

    .date {
      font-size: 11px;
      font-weight: 700;
      color: #979797;
    }

    .title {
      font-weight: 700;
    }

    .body {
      font-size: 14px;
      color: #c4c4c4;
      word-wrap: "break-word";
      opacity: 0.75;
    }
  }

  &.connection-notification {
    display: flex;
  }
}
