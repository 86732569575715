.directory-page {
  .checkbox-filters {
    margin-top: -10px;
  }

  .segmented-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    font-size: 1rem;
    font-weight: bold;
    background: #ffffff;
    border-bottom: 1px solid #d8d6d6;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      padding-bottom: 0;
      margin: auto;
      color: #a3a7b2;
      text-align: center;
      background-color: transparent;
      border-bottom: 3px solid transparent;

      p {
        display: flex;
        margin: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
      }

      &:hover {
        cursor: pointer;
      }
    }

    span:nth-child(1) {
      border-right: 3px solid #f1f2fa;
    }

    span:nth-child(3) {
      border-left: 3px solid #f1f2fa;
    }

    .selected {
      color: @standout;
      border-bottom: 3px solid @standout;
    }

    .noselect {
      margin-bottom: 5px;
    }

    svg {
      margin-right: 8px;
      vertical-align: bottom;
    }
  }

  .directory-page-main {
    margin: 25px 20px;

    .main-content {

      .list-wrapper {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 300px);

        @media screen and (max-width: 600px) {
          height: auto;
        }

        .directory-empty-state {
          margin: 15vh 40px;
        }
      }

      .company-wrapper {
        .company-list {
          width: 100%;
        }
      }
    }

    .extra-information-wrapper {
      min-width: 310px;
      padding: 25px 20px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

      @media screen and (max-width: 1100px) {
        display: none;
      }

      .extra-information-header {
        padding: 0;
      }

      &-title {
        margin-bottom: 10px;
        font-size: 17px;
        font-size: 24px;
        font-weight: 900;
        line-height: 30px;
        color: #4b4c53;
        letter-spacing: 0;
      }
    }

    .view-more-button {
      display: flex;
      align-items: center;
      font-weight: bold;
      color: @red-100;
      transition: 0.15s ease-in-out;

      &:hover {
        opacity: 0.8;
      }

      svg {
        margin-right: 10px;
      }
    }
  }
}

.recommended-companies-section {
  border-bottom: 0;
}
