.agenda-page {
  .header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .main-content {
    width: 100%;
    padding: 20px;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin-left: 0;
    }
  }

  .segmented-control {
    height: 60px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 0px;
      font-size: 24px;
      background-color: white;

      &.noselect {
        padding-bottom: 5px;
      }
    }
  }

  .ant-collapse-content-active {
    box-shadow: 0px 15px 10px -2px rgba(42, 24, 60, 0.1);
  }
}

.agenda-page-filters-dropdown-content {
  z-index: 2;
  min-width: 350px;
  max-width: 350px;

  ul {
    max-height: 400px;
    padding: 1rem;
    padding-top: 20px;
    margin-top: -20px;
    overflow-y: scroll;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    label {
      display: flex;
      word-break: break-word;
      white-space: normal;
    }
  }
}
