.segmented-control {
  display: flex;
  flex: 1;
  align-items: flex-end;
  font-size: 1rem;
  font-weight: bold;
  border-bottom: 1px solid #d8d6d6;

  span {
    margin-right: 50px;
    font-size: 16px;
    line-height: 21px;
    color: #a3a7b2;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    color: @standout;
    border-bottom: 5px solid @standout;
  }

  .noselect {
    margin-bottom: 5px;
  }

  svg {
    margin-right: 8px;
    vertical-align: bottom;
  }
}

.networking-segmented-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
  background: #ffffff;
  border-bottom: 1px solid #d8d6d6;
  z-index: 2;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;

    &:hover {
      cursor: pointer;
    }

    svg {
      margin-right: 8px;
      vertical-align: bottom;
    }

    button {
      box-shadow: none;
      color: #a3a7b2;
      font-size: 1rem;
      width: 100%;
    }
  }

  a:nth-child(1) {
    border-right: 3px solid #f1f2fa;
  }

  a:nth-child(3) {
    border-left: 3px solid #f1f2fa;
  }

  a {
    border-bottom: 3px solid white;
  }

  .selected {
    border-bottom: 3px solid @standout;
    button {
      color: @standout;
    }
  }
}
