.ticket {
  position: relative;
  display: flex;
  width: 180px;
  height: 95px;
  overflow: hidden;
  background: linear-gradient(to right, #ffa225, #fcbf6d);
  border-radius: 6px;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    width: 44px;
    height: 44px;
    content: " ";
    background-color: #f0f2f5;
    border: 6px solid #fcbf6d;
    border-radius: 9999px;
    transform: translateY(-50%);
  }

  &::before {
    left: -22px;
    z-index: 3;
    border-color: #ffa225;
  }

  &::after {
    right: -22px;
  }
}
