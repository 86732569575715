.perk-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: @white;
  border-radius: 10px;

  .perk-card-cover {
    .image-cover-gradient {
      background:
        linear-gradient(
          to right,
          rgba(#000000, 0.03),
          rgba(rgb(78, 78, 78), 0.03)
        );
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &-cover {
    position: relative;
    height: 180px;

    .image-cover {
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .image-cover-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background:
        linear-gradient(
          to right,
          rgba(#000000, 0.03),
          rgba(rgb(78, 78, 78), 0.03)
        );
    }
  }

  &-content {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 26px;

    &-title {
      display: box;
      padding: 15px 14px 0;
      overflow: hidden;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
      color: #4b4c53;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      box-orient: vertical;
    }

    &-description {
      display: box;
      flex: 1;
      margin: 8px 22px 22px 14px;
      overflow: hidden;
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: #a3a7b2;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      box-orient: vertical;
    }

    &-divider {
      width: 100%;
      height: 1px;
      margin-bottom: 22px;
      background: linear-gradient(to right, #dadada 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(blue 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, green 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(red 50%, rgba(255, 255, 255, 0) 0%);
      background-repeat: repeat-x, repeat-y;
      background-position: top, right, bottom, left;
      background-size: 10px 1px, 0px 10px;
    }

    &-footer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 25px;

      &-offer {
        margin-right: auto;
        margin-left: auto;

        .price {
          flex-direction: row;
          font-size: 24px;
          font-weight: 700;
          line-height: 30.36px;

          &-old {
            color: #f01e6a;
            text-decoration: line-through;
          }

          &-new {
            margin-left: 8px;
            color: #a2d57b;
          }
        }

        .note {
          font-size: 16px;
          line-height: 22px;
          color: #a3a7b2;
        }
      }

      a {
        width: 100%;
      }
    }
  }
}
