.content-container {
  padding: 25px;
  background-color: @white;
  border-radius: 10px;
  box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

  #player-container {
    @media screen and (max-width: 600px) {
      position: relative;
      height: 300px !important;
    }
  }

  .qa-button-container {
    display: block;
    text-align: right;

    @media screen and (max-width: 600px) {
      display: none;
    }

    .qa-button {
      z-index: 100;
      display: inline;
      min-width: 230px;
      max-width: 330px;
      height: 45px;
      padding: 12px 20px;
      margin-left: auto;

      svg {
        vertical-align: top;
      }
    }
  }

  .additional-info-container {
    position: relative;
    margin: 10px 0;

    .stream-list-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-top: 25px;
      margin-bottom: 25px;

      .stream-list {
        display: flex;
        flex-wrap: wrap;
      }

      .actions {
        margin-left: 16px;
        text-align: right;
        vertical-align: middle;

        svg {
          vertical-align: -30%;
        }

        .share-text {
          padding: 0;
          margin-left: 3px;
          border: 0;
        }
      }

      @media screen and (max-width: 600px) {
        margin-bottom: 20px;
        text-align: left;
      }

      .stream-box {
        padding: 12px 22px;
        font-size: 15px;
        font-weight: 600;
        color: gray;
        cursor: pointer;
        border: 1px solid #dadada;
        border-width: 1px;

        &.selected {
          color: @btn-color;
          background: @btn-background;
        }

        &:first-of-type {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:last-of-type {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      span:first-child {
        border-left: 1px solid #dadada;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 900px) {
        flex-direction: column;
        margin-bottom: 10px;
      }

      .actions {
        white-space: nowrap;
      }
    }
  }
}
