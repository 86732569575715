.perk-page {
  padding: 20px 0;

  @media screen and (max-width: 600px) {
    justify-content: center;
  }

  .extra-information-wrapper {
    min-width: 420px;
    max-width: 420px;

    @media screen and (max-width: 600px) {
      display: none;
    }

    .extra-information {
      padding-top: 0;
      padding-right: 0;
      padding-left: 40px;
    }
  }

  @media screen and (max-width: 600px) {
    margin-right: 0;
    margin-left: 0;
  }

  .search-divider {
    width: 400px;
    margin-bottom: 31.55px;
    border-bottom: #d8d6d6 1px solid;
  }

  .perk-card-list {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    column-gap: 30px;
    row-gap: 34px;
    border-radius: 4px;
    margin-top: 25px;

    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and (max-width: 992px) {
      row-gap: 20px;
      column-gap: 10px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .perk-card {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.08);

    &:hover {
      box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.08);
    }
  }

  .perk-card-wrapper {
    display: flex;
    justify-content: center;
    order: 6;
  }

  .perk-card-selected {
    border-color: rgb(255, 230, 98);
    border-style: solid;
    border-width: 5px;
    border-radius: 15px;
  }
}
