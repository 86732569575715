.resources-container {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #d5dee1;

  .resource {
    display: flex;
    margin-left: 40px;
    font-size: 18px;
    line-height: 18px;
    color: #000000;

    svg {
      align-self: center;
      transition: 0.15s ease-in-out;
      object-fit: cover;
    }

    h4 {
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;

      svg {
        color: @standout;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    div {
      margin-left: 12px;
    }

    h4 {
      margin-bottom: 0;
    }

    span {
      font-size: 14px;
      color: #84848e;
    }
  }
}
