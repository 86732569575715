.network-status {
  position: fixed;
  top: -100px;
  left: 0;
  z-index: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  color: @white;
  background-color: @green-100;
  transition: background-color 0.15s ease-in-out, top 2s ease-in-out;

  &.offline {
    top: 55px;
    background-color: @red-100;
  }
}
