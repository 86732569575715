.claimed-perks {
  position: sticky;
  top: 57px;
  padding: 25px 0 0px;

  .title-wrapper {
    h4 {
      margin-bottom: 10px;
      font-size: 17px;
      font-weight: bold;
      color: #181a55;
      text-transform: uppercase;
      letter-spacing: 0;
    }
  }

  .perk-item-wrapper {
    margin-top: 10px;
  }

  .perk-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #f8f8f8;
    }

    img {
      border-radius: 50%;
      object-fit: cover;
    }

    .text {
      padding: 0 16px;
    }

    .title {
      font-size: 17px;
      font-weight: 600;
      color: @dark-200;
    }

    .subtitle {
      font-size: 13px;
      color: #4b4c53;

      span:last-child {
        font-weight: bold;
        color: @standout;
        text-transform: uppercase;
      }
    }

    > aside {
      display: flex;
      align-items: center;
    }

    svg {
      margin-left: auto;
      font-size: 20px;
      color: @green-100;
    }
  }
}
