@textLengthCrop: 185px;
@chatHoverIconColor: @standout;

.person-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  &-container {
    display: flex;

    img {
      margin-right: 15px;
      border-radius: 50%;
      object-fit: cover;
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-title {
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        font-size: 17px;
        font-weight: 600;
        line-height: 26px;
        color: #1c1c1d;

        &.truncate {
          width: @textLengthCrop;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .position {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #696d70;
    }
  }

  &__actions {
    display: inline-flex;
    flex-direction: row;

    div {
      &:first-child {
        background: #e9ecff;
        border-radius: 50%;
      }
    }

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 33px;
      margin-left: 10px;
      background: #e9ecff;
      border-radius: 100%;
    }
  }

  svg {
    &:hover {
      color: @chatHoverIconColor;
    }
  }

  .subtitle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding-top: 11px;

    .text {
      padding-right: 6px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #4b4c53;
      margin-right: 0;
    }

    span {
      color: @standout;
      background-color: transparent;
      font-size: 15px;
      font-weight: 600;
      margin: 0 10px 0 0;
      padding: 0;
    }
  }

  .questionAnswer {
    margin-top: 5px;
    font-size: 15px;
    font-weight: 600;
    color: @standout;

    .question {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #4b4c53;
    }

    .answersIcon {
      position: absolute;
      width: 16px;
      height: 16px;
      margin-top: 3px;
      margin-left: 5px;
      font-weight: 700;
      line-height: 17px;
      color: #ffffff;
      text-align: center;
      background-color: @standout;
      border-radius: 999px;
      font-size: 13px;
    }
  }
}

.questionPopover {
  margin-right: -20px;

  .ant-popover-arrow {
    right: 7px !important;
    border-top-color: @primary !important;
    border-left-color: @primary !important;
  }

  .ant-popover-inner {
    background-color: @primary;
  }

  .ant-popover-inner-content {
    min-width: 177px;
    min-height: 32px;
    font-size: 15px;
    font-weight: 600;
    color: @tertiary;

    p {
      margin-bottom: 5px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .popover-question-text {
    color: @secondary;
    font-weight: 400;
  }
}
