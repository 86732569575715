.directory-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  color: @red-100;

  img {
    width: 75px;
    margin: 30px 0;
  }
}
