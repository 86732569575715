.super-card {
  height: 100%;
  min-height: 680px;
  color: #ffffff;
  background-color: transparent;
  perspective: 1000px;

  &.inverted {
    .super-card__inner {
      color: #000000;

      &__front__play__btn,
      &__back__finished-btn {
        background-color: #000000;
      }

      &__front__checks__ul__li__icon {
        background-color: #000000 !important;
        border-color: #000000 !important;
      }

      &__front__footer__popover__i-icon {
        color: #ffffff;
        background: #000000;
      }

      &__front__footer__view-company,
      &__back__footer__view-company {
        color: #000000;
        border: 1px solid #000000;

        &:hover {
          color: #ffffff;
          background-color: #000000;
        }
      }
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);
    transition: transform 0.6s;
    transform-style: preserve-3d;

    &.flipped {
      transform: rotateY(180deg);
    }

    &__front,
    &__back {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 35px 25px 20px;
      border-radius: 10px;
      backface-visibility: hidden;
    }

    &__front {
      gap: 20px;

      &__header {
        &__avatar {
          margin-bottom: 25px;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        }

        &__skeleton-title {
          margin: 0 auto;

          h3 {
            margin: 0 auto;
          }
        }

        &__title {
          display: block;
          margin-bottom: 20px;
          font-size: 28px;
          font-weight: 700;
          line-height: 30px;
          color: inherit;

          &:hover {
            color: inherit;
            text-decoration: underline;
          }
        }

        &__subtitle {
          min-height: 20px;

          &__text {
            display: block;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
          }
        }

        &__description {
          height: 65px;
          margin-top: 15px;
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
        }
      }

      &__play {
        height: 30px;

        &__btn {
          display: flex;
          align-items: center;
          padding: 9px 14px 9px 12px;
          margin: auto;
          cursor: pointer;
          border-radius: 30px;

          &:hover {
            opacity: 0.7;
          }

          &__text {
            padding-left: 5px;
            font-size: 16px;
            font-weight: 800;
          }
        }
      }

      &__checks {
        &__ul {
          padding: 0;
          list-style: none;

          &__li {
            display: flex;
            align-items: center;
            padding: 0.7rem;

            &__icon {
              flex-shrink: 0;
              width: 34px !important;
              height: 34px !important;
              background-color: #ffffff !important;
              border-color: #ffffff !important;
            }

            &__text {
              padding-left: 1rem;
              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              text-align: left;
              word-wrap: break-word;
              white-space: normal;
            }
          }
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: auto;

        &__popover {
          max-width: 225px;
          padding: 12px 20px;
          margin: auto;
          font-size: 16px;
          font-weight: 700;
          background: rgba(35, 11, 52, 0.1);
          border-radius: 28px;

          &__i-icon {
            position: relative;
            display: inline-block;
            width: 23px;
            height: 23px;
            padding-top: 1px;
            margin-left: 4px;
            font-weight: 700;
            line-height: 23px;
            color: #000000;
            cursor: pointer;
            background: #ffffff;
            border-radius: 100%;
          }
        }

        &__hiring {
          max-width: 140px;
          margin: auto;
          font-size: 1.2rem;
          font-weight: 600;
          color: inherit;
          border-bottom: 4px solid;
        }

        &__view-company {
          padding: 14px 0 13px;
          font-size: 20px;
          font-weight: 700;
          line-height: 27px;
          color: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 10px;
          transition: 0.2s;

          &:hover {
            color: #000000;
            background-color: #ffffff;
          }
        }
      }
    }

    &__back {
      position: absolute;
      top: 0;
      transform: rotateY(180deg);

      &__header {
        margin-bottom: 30px;

        &__avatar {
          margin-bottom: 25px;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        }

        &__title {
          display: block;
          margin-bottom: 20px;
          font-size: 28px;
          font-weight: 700;
          line-height: 30px;
          color: inherit;

          &:hover {
            color: inherit;
            text-decoration: underline;
          }
        }

        &__subtitle {
          min-height: 20px;

          &__text {
            display: block;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }

      .post-video-player iframe {
        width: 100%;
        max-width: 100%;
      }

      &__finished-btn {
        display: flex;
        align-items: center;
        padding: 9px 14px 9px 12px;
        margin: auto;
        cursor: pointer;
        border-radius: 30px;

        &:hover {
          opacity: 0.7;
        }

        &__text {
          padding-left: 5px;
          font-size: 16px;
          font-weight: 600;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: auto;

        &__view-company {
          padding: 14px 0 13px;
          font-size: 20px;
          font-weight: 700;
          line-height: 27px;
          color: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 10px;
          transition: 0.2s;

          &:hover {
            color: #000000;
            background-color: #ffffff;
          }
        }
      }
    }
  }
}
