.forum-action {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 600;
  color: #8b8ca3;

  span {
    min-width: 90px;
    margin-right: 10px;
    margin-left: 10px;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    width: 100%;

    span {
      margin: 20px 0 3px;
    }
  }

  img {
    height: 100%;

    @media screen and (max-width: 1500px) {
      max-width: 100%;
      height: auto;
    }

    @media screen and (max-width: 1200px) {
      margin-bottom: 15px;
      margin-left: 0;
    }

    @media screen and (max-width: 600px) {
      margin: 0;
      margin-bottom: 0;
    }
  }
}
