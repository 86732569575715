.speaker-information-container {
  display: flex;
  line-height: 22px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  &-image {
    @media screen and (max-width: 600px) {
      margin-bottom: 20px;
      margin-left: 0 !important;
    }
  }

  img {
    min-width: 110px;
    min-height: 110px;
    border-radius: 9999px;

    @media screen and (max-width: 600px) {
      width: 80px;
      min-width: 80px;
      height: 80px;
      min-height: 80px;
      margin-bottom: 20px;
    }
  }

  .ant-avatar-image {
    @media screen and (max-width: 600px) {
      width: 80px !important;
      height: 80px !important;
    }
  }

  a {
    display: inline-block;
    margin-bottom: 0;
    font-size: 19px;
    font-weight: bold;
    color: #000000;

    &:hover {
      text-decoration: underline;
    }
  }

  .speaker-position {
    display: block;
    font-size: 17px;
    color: #000000;
  }

  p {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: #000000;
  }

  div {
    margin-left: 30px;

    @media screen and (max-width: 600px) {
      margin-left: 0;
    }
  }
}
