.adw-white-input {
  width: 100%;
  max-width: 320px;
  height: 50px;

  padding: 0.6rem !important;
  background-color: white !important;
  border-style: none !important;
  border-radius: 10px !important;

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    outline: none;
    box-shadow: none;
  }

  .ant-input-suffix {
    padding-left: 5px;
  }

  .ant-input-prefix {
    padding-right: 5px;
  }

  .ant-input {

    font-size: 1rem;

    &::input-placeholder {
      font-size: 15px;
      font-weight: 400;
    }

    &::placeholder {
      font-size: 15px;
      font-weight: 700;
    }

    &:input-placeholder {
      font-size: 15px;
      font-weight: 700;
    }

    &:-o-input-placeholder {
      font-size: 15px;
      font-weight: 700;
    }

    &:focus,
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
}

.con-white-input {
  width: 100%;
  min-width: 130px;
  max-width: 400px;
  height: 50px;

  margin-top: 10px;
  border-style: none;
  border-radius: 10px;
  box-shadow: none;

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    outline: none;
    box-shadow: none;
  }

  .ant-input-suffix {
    padding-left: 5px;
  }

  .ant-input-prefix {
    padding-right: 5px;
  }

  .ant-input {

    font-size: 1rem;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: rgb(163, 167, 178);
    }

    &:-o-input-placeholder {
      font-size: 14px;
      font-weight: 400;
      color: rgb(163, 167, 178);
    }

    &:focus,
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
}
