.activity-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: @white;
  border-radius: 10px;
  box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

  @media only screen and (max-width: 1199px) {
    height: 100% !important;
  }

  .now-streamings-container {
    margin-bottom: 15px;
  }

  .live-talk {
    margin-bottom: 20px;

    img {
      margin-left: 10px;
    }

    h5 {
      margin-top: 10px;
    }
  }

  .streaming-now-post {
    padding: 7px 12px 0;
    margin-bottom: 16px;
    border: 2px solid @red-100;
    border-radius: 5px;
  }

  .streaming-now-button {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: default;
    background-color: @red-100;
    border: none;

    span {
      margin-left: 4px;
    }
  }

  .post {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: #000000;

    .post-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .post-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      &-speaker {
        margin-bottom: 0;
        font-size: 14px;
        color: #687376;
      }

      &-title {
        margin: 7px 0;
        overflow: hidden;
        line-height: 22px;
        -webkit-line-clamp: 2;
        box-orient: vertical;
      }

      &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        div span {
          margin-left: 6px;
        }

        &-time {
          font-size: 14px;
          color: #687376;

          svg {
            margin-top: -3px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .title {
    position: sticky;
    top: -19px;
    z-index: 10;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 600;
    font-weight: bold;
    color: @black;
    text-transform: uppercase;
    background: white;
  }

  .extra-activity-container {
    padding: 18px 20px;
  }

  .extra-activity-container {
    height: 100%;
    overflow: auto;

    .post {
      padding: 12px 0;
      border-top: 1px solid lightgray;

      &:first-of-type {
        border-top: 0;
      }
    }
  }
}
