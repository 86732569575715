.play-button-stream,
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 105px;
  height: 105px;
  border-radius: 100px;
  transform: translate(-50%, -50%);

  &:hover {
    cursor: pointer;
  }

  &:hover div {
    width: 90px;
    height: 90px;
  }

  &:hover .play-icon {
    width: 40px !important;
    height: 40px;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 86px;
    height: 86px;
    background-color: #1c1a54;
    border-radius: 100px;
    transition: 0.15s ease-in-out;
    transform: translate(-50%, -50%);
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 46px !important;
    height: 46px;
    transition: 0.15s ease-in-out;
    transform: translate(-50%, -50%);

    svg {
      vertical-align: middle;
      color: #f9424b;
    }
  }

  .donut-icon {
    width: 125px;
    height: 125px;
    margin-top: -10px;
    margin-left: -10px;
    transform: rotate(-90deg);

    .animate {
      animation-name: donutAnimation;
      animation-duration: 10s;
      animation-timing-function: linear;
    }
  }
}

@keyframes donutAnimation {
  0% {
    stroke-dasharray: 0 100;
  }

  100% {
    stroke-dasharray: 100 100;
  }
}
